import { getToken } from '..';
import {
	fetchOrderHistoryTickets,
	fetchOrderTicketDetails,
	getSchoolBulkCardOrders,
	getSchoolBulkCardOrderById,
	fetchFinishedTickets,
	fetchLoanTransactions,
	getSchoolBulkSpecificCardOrderById,
	fetchBulkProducts,
	createBulkMultipleCardOrder,
	getPendingBulkSpecificOrders,
} from '../../api';
import { getSchool } from '../school';
import { isEmpty } from 'lodash';
import {
	selectStudentTickets,
	getReferredAccountTickets,
} from '../students';

const initialState = {
	errorMessage: '',
	ticketOrders: undefined,
	detailedTicketOrders: undefined,
	bulkOrders: [],
	bulkSpecificOrders: [],
	pendingBulkSpecificOrders: [],
	finishedTickets: [],
	loanTransactions: [],
	referredAccountLoanTransactions: [],
	isLoadingTicketOrders: false,
	isLoadingTicketOrderDetails: false,
	isLoadingBulkCardOrders: false,
	isLoadingBulkProducts: false,
};

export const ORDER_ERRORS = {
	GET_TICKET_ORDERS: 'GET_TICKET_ORDERS',
	GET_TICKET_ORDER_DETAILS: 'GET_TICKET_ORDER_DETAILS',
	GET_SCHOOL_BULK_CARD_ORDERS: 'GET_SCHOOL_BULK_CARD_ORDERS',
	GET_SCHOOL_BULKSPECIFIC_CARD_ORDERS: 'GET_SCHOOL_BULKSPECIFIC_CARD_ORDERS',
	GET_PENDING_CARD_ORDERS: 'GET_PENDING_CARD_ORDERS',
	GET_FINISHED_TICKETS: 'GET_FINISHED_TICKETS',
	GET_LOAN_TRANSACTIONS: 'GET_LOAN_TRANSACTIONS',
	GET_BULK_PRODUCTS: 'GET_BULK_PRODUCTS',
	CREATE_BULK_MULTIPLE_CARD_ORDER_: 'CREATE_BULK_MULTIPLE_CARD_ORDER',
};

export const STORE_NAME = 'orders';

export const actionCreators = {
	getTicketOrders: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_TICKET_ORDERS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const ticketOrders = await fetchOrderHistoryTickets(
				token,
				school.schoolId
			);

			dispatch({ type: 'GET_TICKET_ORDERS_SUCCESS', ticketOrders });
		} catch (error) {
			dispatch({
				type: 'GET_TICKET_ORDERS_FAILURE',
				message: ORDER_ERRORS.GET_TICKET_ORDERS,
			});
		}
	},
	getTicketOrderDetails: orderId => async (dispatch, getState) => {
		dispatch({ type: 'GET_TICKET_ORDER_DETAILS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const orderDetails = await fetchOrderTicketDetails(
				token,
				school.schoolId,
				orderId
			);

			dispatch({ type: 'GET_TICKET_ORDER_DETAILS_SUCCESS', orderDetails });
		} catch (error) {
			dispatch({
				type: 'GET_TICKET_ORDER_DETAILS_FAILURE',
				message: ORDER_ERRORS.GET_TICKET_ORDER_DETAILS,
			});
		}
	},
	getBulkCardOrders: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_SCHOOL_BULK_CARD_ORDERS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const { bulkOrders, bulkSpecificOrders } = await getSchoolBulkCardOrders(
				token,
				school.schoolId
			);
			dispatch({
				type: 'GET_SCHOOL_BULK_CARD_ORDERS_SUCCESS',
				bulkOrders,
				bulkSpecificOrders,
			});
		} catch (error) {
			dispatch({
				type: 'GET_SCHOOL_BULK_CARD_ORDERS_FAILURE',
				message: ORDER_ERRORS.GET_SCHOOL_BULK_CARD_ORDERS,
			});
		}
	},
	getPendingCardOrders: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_SCHOOL_PENDING_ORDERS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const pendingStudentIds = await getPendingBulkSpecificOrders(
				token,
				school.schoolId
			);
			dispatch({
				type: 'GET_SCHOOL_PENDING_ORDERS_SUCCESS',
				pendingStudentIds,
			});
		} catch (error) {
			dispatch({
				type: 'GET_SCHOOL_PENDING_ORDERS_FAILURE',
				message: ORDER_ERRORS.GET_PENDING_CARD_ORDERS,
			});
		}
	},
	getBulkCardOrderById: orderId => async (dispatch, getState) => {
		dispatch({ type: 'GET_SCHOOL_BULK_CARD_ORDER_BY_ID_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const order = await getSchoolBulkCardOrderById(
				token,
				school.schoolId,
				orderId
			);
			dispatch({
				type: 'GET_SCHOOL_BULK_CARD_ORDER_BY_ID_SUCCESS',
				order,
			});
		} catch (error) {
			dispatch({
				type: 'GET_SCHOOL_BULK_CARD_ORDER_BY_ID_FAILURE',
				message: ORDER_ERRORS.GET_SCHOOL_BULK_CARD_ORDERS,
			});
		}
	},
	getBulkSpecificCardOrderById: orderId => async (dispatch, getState) => {
		dispatch({ type: 'GET_SCHOOL_BULK_SPECIFIC_CARD_ORDER_BY_ID_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const order = await getSchoolBulkSpecificCardOrderById(
				token,
				school.schoolId,
				orderId
			);
			dispatch({
				type: 'GET_SCHOOL_BULK_SPECIFIC_CARD_ORDER_BY_ID_SUCCESS',
				order,
			});
		} catch (error) {
			dispatch({
				type: 'GET_SCHOOL_BULK_SPECIFIC_CARD_ORDER_BY_ID_FAILURE',
				message: ORDER_ERRORS.GET_SCHOOL_BULKSPECIFIC_CARD_ORDERS,
			});
		}
	},
	getFinishedTickets: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_FINISHED_TICKETS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const finishedTickets = await fetchFinishedTickets(
				token,
				school.schoolId
			);
			dispatch({ type: 'GET_FINISHED_TICKETS_SUCCESS', finishedTickets });
		} catch (error) {
			dispatch({
				type: 'GET_FINISHED_TICKETS_FAILURE',
				message: ORDER_ERRORS.GET_FINISHED_TICKETS,
			});
		}
	},

	getLoanTransactions: travellerId => async (dispatch, getState) => {
		dispatch({ type: 'GET_LOAN_TRANSACTIONS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		const studentTickets = selectStudentTickets(state)(travellerId);
		if (!isEmpty(studentTickets)) {
			const mtbProductIds = (studentTickets || [])
				.map(ticket => ticket.id)
				.join();

			try {
				const loanTransactions = await fetchLoanTransactions(
					token,
					school.schoolId,
					mtbProductIds
				);

				dispatch({ type: 'GET_LOAN_TRANSACTIONS_SUCCESS', loanTransactions });
			} catch (error) {
				dispatch({
					type: 'GET_LOAN_TRANSACTIONS_FAILURE',
					message: ORDER_ERRORS.GET_LOAN_TRANSACTIONS,
				});
			}
		} else
			dispatch({
				type: 'GET_LOAN_TRANSACTIONS_CANCEL',
			});
	},
	getReferredAccountLoanTransactions: travellerId => async (dispatch, getState) => {
		dispatch({ type: 'GET_LOAN_TRANSACTIONS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		const tickets = getReferredAccountTickets(state)(travellerId);

		if (!isEmpty(tickets)) {
			const mtbProductIds = (tickets || [])
				.map(ticket => ticket.id)
				.join();

			try {
				const referredAccountLoanTransactions = await fetchLoanTransactions(
					token,
					school.schoolId,
					mtbProductIds
				);

				dispatch({ type: 'GET_REFERRED_ACCOUNT_LOAN_TRANSACTIONS_SUCCESS', referredAccountLoanTransactions });
			} catch (error) {
				dispatch({
					type: 'GET_REFERRED_ACCOUNT_LOAN_TRANSACTIONS_FAILURE',
					message: ORDER_ERRORS.GET_LOAN_TRANSACTIONS,
				});
			}
		} else
			dispatch({
				type: 'GET_REFERRED_ACCOUNT_LOAN_TRANSACTIONS_CANCEL',
			});
	},
	getBulkProducts: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_BULK_PRODUCTS_REQUEST' });
		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const bulkProducts = await fetchBulkProducts(token, school.schoolId);
			dispatch({ type: 'GET_BULK_PRODUCTS_SUCCESS', bulkProducts });
		} catch (error) {
			dispatch({
				type: 'GET_BULK_PRODUCTS_FAILURE',
				message: ORDER_ERRORS.GET_BULK_PRODUCTS,
			});
		}
	},
	createBulkMultipleCardOrder: order => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_BULK_MULTIPLE_CARD_ORDER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);
		try {
			const multipleCardOrder = await createBulkMultipleCardOrder(
				token,
				school.schoolId,
				order
			);
			dispatch({
				type: 'CREATE_BULK_MULTIPLE_CARD_ORDER_SUCCESS',
				multipleCardOrder,
			});
		} catch (error) {
			dispatch({
				type: 'CREATE_BULK_MULTIPLE_CARD_ORDER_FAILURE',
				message: ORDER_ERRORS.CREATE_BULK_MULTIPLE_CARD_ORDER_,
			});
			throw error;
		}
	},
};

export default function ordersReducer(state = initialState, action) {
	switch (action.type) {
		case 'GET_TICKET_ORDERS_REQUEST':
			return {
				...state,
				isLoadingTicketOrders: true,
				errorMessage: '',
			};
		case 'GET_TICKET_ORDER_DETAILS_REQUEST':
			return {
				...state,
				isLoadingTicketOrderDetails: true,
				errorMessage: '',
			};
		case 'GET_SCHOOL_BULK_CARD_ORDERS_REQUEST':
		case 'GET_SCHOOL_BULK_CARD_ORDER_BY_ID_REQUEST':
		case 'GET_SCHOOL_BULK_SPECIFIC_CARD_ORDER_BY_ID_REQUEST':
		case 'GET_SCHOOL_PENDING_ORDERS_REQUEST':
			return {
				...state,
				isLoadingBulkCardOrders: true,
				errorMessage: '',
			};
		case 'GET_FINISHED_TICKETS_REQUEST':
			return {
				...state,
				isLoadingFinishedTickets: true,
				errorMessage: '',
			};
		case 'GET_LOAN_TRANSACTIONS_REQUEST':
			return {
				...state,
				isLoadingLoanTransactions: true,
				errorMessage: '',
			};
		case 'GET_BULK_PRODUCTS_REQUEST':
			return {
				...state,
				isLoadingBulkProducts: true,
				errorMessage: '',
			};
		case 'CREATE_BULK_MULTIPLE_CARD_ORDER_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_TICKET_ORDERS_SUCCESS':
			return {
				...state,
				ticketOrders: action.ticketOrders,
				isLoadingTicketOrders: false,
			};
		case 'GET_TICKET_ORDER_DETAILS_SUCCESS':
			return {
				...state,
				detailedTicketOrders: {
					...state.detailedTicketOrders,
					[action.orderDetails.ticketOrderId]: action.orderDetails,
				},
				isLoadingTicketOrderDetails: false,
			};
		case 'GET_SCHOOL_BULK_CARD_ORDERS_SUCCESS':
			return {
				...state,
				bulkOrders: action.bulkOrders,
				bulkSpecificOrders: action.bulkSpecificOrders,
				isLoadingBulkCardOrders: false,
			};
		case 'GET_SCHOOL_PENDING_ORDERS_SUCCESS':
			return {
				...state,
				pendingBulkSpecificOrders: action.pendingStudentIds,
				isLoadingBulkCardOrders: false,
			};
		case 'GET_SCHOOL_BULK_CARD_ORDER_BY_ID_SUCCESS':
			return {
				...state,
				bulkOrders: [
					...state.bulkOrders.filter(
						o => o.cardOrderId !== action.order.cardOrderId
					),
					action.order,
				],
				isLoadingBulkCardOrders: false,
			};
		case 'GET_SCHOOL_BULK_SPECIFIC_CARD_ORDER_BY_ID_SUCCESS':
			return {
				...state,
				bulkSpecificOrders: [
					...state.bulkSpecificOrders.filter(
						o => o.cardOrderId !== action.order.cardOrderId
					),
					action.order,
				],
				isLoadingBulkCardOrders: false,
			};
		case 'GET_FINISHED_TICKETS_SUCCESS':
			return {
				...state,
				finishedTickets: action.finishedTickets,
				isLoadingFinishedTickets: false,
			};
		case 'GET_LOAN_TRANSACTIONS_SUCCESS':
			return {
				...state,
				loanTransactions: action.loanTransactions,
				isLoadingLoanTransactions: false,
			};
		case 'GET_REFERRED_ACCOUNT_LOAN_TRANSACTIONS_SUCCESS':
			return {
				...state,
				referredAccountLoanTransactions: action.referredAccountLoanTransactions,
				isLoadingLoanTransactions: false,
			};
		case 'GET_BULK_PRODUCTS_SUCCESS':
			return {
				...state,
				bulkProducts: action.bulkProducts,
				isLoadingBulkProducts: false,
			};
		case 'CREATE_BULK_MULTIPLE_CARD_ORDER_SUCCESS':
			return {
				...state,
				isLoading: false,
				multipleCardOrder: action.multipleCardOrder,
			};
		case 'CREATE_BULK_CARD_ORDERS_SUCCESS': //Bulkspecific cart success
			return {
				...state,
				pendingBulkSpecificOrders: [
					...state.pendingBulkSpecificOrders,
					...action.orderedStudentIds,
				],
			};
		case 'GET_TICKET_ORDERS_FAILURE':
			return {
				...state,
				isLoadingTicketOrders: false,
				errorMessage: action.message,
			};
		case 'GET_TICKET_ORDER_DETAILS_FAILURE':
			return {
				...state,
				isLoadingTicketOrderDetails: false,
				errorMessage: action.message,
			};
		case 'GET_SCHOOL_BULK_CARD_ORDERS_FAILURE':
		case 'GET_SCHOOL_BULK_CARD_ORDER_BY_ID_FAILURE':
		case 'GET_SCHOOL_PENDING_ORDERS_FAILURE':
			return {
				...state,
				isLoadingBulkCardOrders: false,
				errorMessage: action.message,
			};
		case 'GET_FINISHED_TICKETS_FAILURE':
			return {
				...state,
				isLoadingFinishedTickets: false,
				errorMessage: action.message,
			};
		case 'GET_LOAN_TRANSACTIONS_FAILURE':
		case 'GET_REFERRED_ACCOUNT_LOAN_TRANSACTIONS_FAILURE':
			return {
				...state,
				isLoadingLoanTransactions: false,
				errorMessage: action.message,
			};
		case 'GET_BULK_PRODUCTS_FAILURE':
			return {
				...state,
				isLoadingBulkProducts: false,
				errorMessage: action.message,
			};
		case 'CREATE_BULK_MULTIPLE_CARD_ORDER_FAILURE':
			return {
				...state,
				isLoadingBulkProducts: false,
				errorMessage: action.message,
			};
		case 'GET_LOAN_TRANSACTIONS_CANCEL':
		case 'GET_REFERRED_ACCOUNT_LOAN_TRANSACTIONS_CANCEL':
			return {
				...state,
				isLoadingLoanTransactions: false,
			};
		case 'CLEAR_SCHOOL_STATE':
			return { ...state, ...initialState };
		default:
			return state;
	}
}

// Selectors

export const getTicketOrders = store => store[STORE_NAME].ticketOrders || [];

export const getFinishedTickets = store =>
	store[STORE_NAME].finishedTickets || [];

export const isLoadingTicketOrders = store =>
	store[STORE_NAME].isLoadingTicketOrders;

export const getTicketOrderDetails = store => orderId =>
	store[STORE_NAME].detailedTicketOrders &&
	store[STORE_NAME].detailedTicketOrders[orderId];

export const getFinishedTicket = store => serialNumber =>
	store[STORE_NAME].finishedTickets.find(t => t.serialNumber === serialNumber);

export const isLoadingTicketOrderDetails = store =>
	store[STORE_NAME].isLoadingTicketOrderDetails;

export const getBulkCardOrders = store => store[STORE_NAME].bulkOrders;

export const getBulkSpecificCardOrders = store =>
	store[STORE_NAME].bulkSpecificOrders;

export const getPendingBulkSpecificStudentIds = store =>
	store[STORE_NAME].pendingBulkSpecificOrders;

export const getBulkCardOrder = store => orderId =>
	store[STORE_NAME].bulkOrders.find(order => order.cardOrderId === orderId);

export const getBulkSpecificCardOrder = store => orderId =>
	store[STORE_NAME].bulkSpecificOrders.find(
		order => order.cardOrderId === orderId
	);

export const isLoadingBulkCardOrders = store =>
	store[STORE_NAME].isLoadingBulkCardOrders;

export const isLoadingFinishedTickets = store =>
	store[STORE_NAME].isLoadingFinishedTickets;

export const hasGetBulkCardOrderError = store =>
	store[STORE_NAME].errorMessage === ORDER_ERRORS.GET_SCHOOL_BULK_CARD_ORDERS;

export const hasFinishedTickets = store =>
	!isEmpty(store[STORE_NAME].finishedTickets);

export const getLoanTransactionByProductId = store => productId =>
	(store[STORE_NAME].loanTransactions || []).find(
		transaction =>
			transaction.items ||
			[].some(item =>
				(item.mtbProductIds || []).map(p => p.id).includes(productId)
			)
	);

export const isSchoolTicketCancellable = store => productId => {
	let transaction = getLoanTransactionByProductId(store)(productId);
	if (transaction) return transaction.cancellable;
	return false;
};

export const getReferredAccountLoanTransactionByProductId = store => productId => 
	(store[STORE_NAME].referredAccountLoanTransactions || []).find(
		transaction =>
			transaction.items ||
			[].some(item =>
				(item.mtbProductIds || []).map(p => p.id).includes(productId)
			)
	);

export const isReferredSchoolTicketCancellable = store => productId => {
	let transaction = getReferredAccountLoanTransactionByProductId(store)(productId);
	if (transaction) return transaction.cancellable;
	return false;
};

export const bulkProducts = store => store[STORE_NAME].bulkProducts;

export const isLoadingBulkProducts = store =>
	store[STORE_NAME].isLoadingBulkProducts;

export const getBulkMultipleCardOrderResponse = store =>
	store[STORE_NAME].multipleCardOrder;

export const hasProcessingTicketOrders = store =>
	(store[STORE_NAME].ticketOrders || []).find(t => isOrderStatusProcessing(t.status)) != null;

export const hasProcessingBulkCardOrders = store =>
	(store[STORE_NAME].bulkOrders || []).find(t => isOrderStatusProcessing(t.status)) != null;

export const isTicketOrderProcessing = store => orderId => 
	store[STORE_NAME].detailedTicketOrders &&
	store[STORE_NAME].detailedTicketOrders[orderId] &&
	isOrderStatusProcessing(store[STORE_NAME].detailedTicketOrders[orderId].status)

export const isCardOrderProcessing = store => orderId => {
	const order = (store[STORE_NAME].bulkSpecificOrders || []).find(
		o => o.cardOrderId === orderId
	);

	return order != null && isOrderStatusProcessing(order.status);
}

export const isOrderStatusProcessing = status =>
	status === 'Beställd' || status === 'Godkänd';
